@import 'containers/app/app.scss';

.SLIDER_BAR-container {
    width: 100%;
    height: 7px;

    cursor: pointer;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
}

.SLIDER_BAR-container::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background: $darkGrey;
 }
 .SLIDER_BAR-container::-webkit-slider-thumb:hover {
    box-shadow: 0px 0px 0px 10px rgba(166, 166, 166, .5);
 }
 .SLIDER_BAR-container::-webkit-slider-thumb:active {
    height: 25px;
    width: 25px;
 }

 @media #{$desktop} {
   .SLIDER_BAR-container {
     height: 8px;
   }
 }