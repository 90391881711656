@import 'containers/app/app.scss';

.NUMBER_INPUT-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 160px;
    border-bottom: 3px solid $lightGrey;

    .NUMBER_INPUT-measurement {
        color: $lightGrey;
        position: absolute;
        pointer-events: none;
    }

    .NUMBER_INPUT-number {
        width: 100%;
        font-size: $text-m;
        font-weight: bold;
        color: $darkGrey;
        border: none;
        outline: none;
    }

    // hides the up and down arrows
    .NUMBER_INPUT-number::-webkit-inner-spin-button, 
    .NUMBER_INPUT-number::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
    }
}

@media #{$mobile} {
    .NUMBER_INPUT-container {
      align-items: baseline;
      width: 40vw;
      border-bottom: 3px solid $lightGrey;
      margin-top: 20px;
    }
}