@import 'containers/app/app.scss';

.MOBILE_PROFILE_PAGE_HEADER-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $lightGrey;

    .MOBILE_PROFILE_PAGE_HEADER-username {
        font-size: $text-m;
        font-weight: normal;
        max-width: 70vw;
        overflow-wrap: anywhere;
    }    
}