@import 'containers/app/app.scss';

.TIME_INPUT-container {
    display: flex;

    .TIME_INPUT-firstInput {
        margin-right: $spacing-s;
    }
}

@media #{$mobile} {
    .TIME_INPUT-container {
        flex-direction: column;

        .TIME_INPUT-firstInput {
            justify-content: flex-start;
            margin-right: 0;
            margin-bottom: $spacing-s;
        }

        .TIME_INPUT-secondInput {
            justify-content: flex-start;
        }
    }
}