@import 'containers/app/app.scss';

.MOBILE_QUESTION_PAGE-container {
    display: flex;
    flex-direction: column;
    width: 100vw;

    .MOBILE_QUESTION_PAGE-topPanel {
        display: flex;
        flex-direction: column;
        width: 90%;
        padding: 5vw;
        background-size: cover;
        background-repeat: no-repeat;
        color: white;
        filter: grayscale(100%);
    }

    .MOBILE_QUESTION_PAGE-descriptionBar {
        display: flex;
        flex-direction: column;

        .MOBILE_QUESTION_PAGE-description {
            color: $lightGrey;
        }

        .MOBILE_QUESTION_PAGE-descriptionTitle {
            color: white;
        }
    }

    .MOBILE_QUESTION_PAGE-progressContainer {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        width: 90%;

        .MOBILE_QUESTION_PAGE-progressBarContainer {
            display: flex;
            flex-direction: row;
            height: 8px;
            margin-bottom: 20px;

            .MOBILE_QUESTION_PAGE-leftProgressBar {
                background: white;
            }
            .MOBILE_QUESTION_PAGE-rightProgressBar {
                background: $darkGrey;
            }
        }

        .MOBILE_QUESTION_PAGE-progressText {
            color: $lightGrey;
        }
    }

    .MOBILE_QUESTION_PAGE-previousQuestionText {
        color: $lightGrey;
    }

    .MOBILE_QUESTION_PAGE-topBar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4vh;

        .MOBILE_QUESTION_PAGE-title {
            color: white;
        }
    }

    .MOBILE_QUESTION_PAGE-bottomPanel {
        display: flex;
        flex-direction: column;
        padding: 5vw;

        .MOBILE_QUESTION_PAGE-nextButtonContainer {
            display: flex;
            justify-content: flex-end;
            margin-bottom: $spacing-xl;

            .MOBILE_QUESTION_PAGE-nextButton {
                align-self: flex-end;
            }
        }
    }
    
}
