@import 'containers/app/app.scss';

.COUNTDOWN_TIMER-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 300px;

    .COUNTDOWN_TIMER-circleContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border: solid 1px $darkGrey;
        background: white;
        cursor: pointer;
    }
    .COUNTDOWN_TIMER-circleContainer:hover {
        background: $lightGrey;
    }

    .COUNTDOWN_TIMER-heightBlock {
        min-height: 70px;
    }

    .COUNTDOWN_TIMER-controlButtonsContainer {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        min-height: 70px;

        .COUNTDOWN_TIMER-restartButton {
            min-height: 20px;
            margin-top: $spacing-m;
        }

        .COUNTDOWN_TIMER-endButton {
            min-height: 20px;
            margin-left: $spacing-xs;
        }
    }

    .COUNTDOWN_TIMER-numberInput {
        margin-right: 0px;
    }
}

@media #{$mobile} {
    .COUNTDOWN_TIMER-container {
        min-height: 100%;
    
        .COUNTDOWN_TIMER-circleContainer {
            width: 100px;
            height: 100px;
        }
    
        .COUNTDOWN_TIMER-heightBlock {
            min-height: 50px;
        }
    
        .COUNTDOWN_TIMER-controlButtonsContainer {
            min-height: 50px;
    
            .COUNTDOWN_TIMER-restartButton {
                margin-top: $spacing-s;
            }
        }
    
        .COUNTDOWN_TIMER-numberInput {

        }
    }
}

@media #{$desktop} {
    .COUNTDOWN_TIMER-container {
        min-width: 500px;
        min-height: 400px;

        .COUNTDOWN_TIMER-controlButtonsContainer {
            min-height: 50px;
        }
    }
}