@import 'containers/app/app.scss';

@font-face {
  font-display: swap;
  font-family: 'Luminari';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./fonts/41097175-a08d-46fd-8e3f-fe200326c295.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/3d7feed1-6ee7-409a-b47f-f7520ce27c41.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local(''),
      url('./fonts/lato-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('./fonts/lato-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  margin: 0px;
  padding: 0px;
  line-height: 1.6;
}


.smallMarginBottom {
  margin-bottom: $spacing-s;
}
.mediumMarginBottom {
  margin-bottom: $spacing-m;
}
.largeMarginBottom {
  margin-bottom: $spacing-l;
}

@media #{$desktop} {
  .smallMarginBottom {
    margin-bottom: $spacing-l;
  }
  .mediumMarginBottom {
    margin-bottom: $spacing-l;
  }
  .largeMarginBottom {
    margin-bottom: $spacing-xl;
  }

  .smallTextSize, .smallLinkSize {
    font-size: $text-m;
  }
  .textSize, .linkTextSize {
    font-size: $text-l;
  }
  .bigTextSize {
    font-size: $text-xl;
  }

  .smallTitleSize, .smallLinkTextSize {
    font-size: $text-l;
  }
  .titleSize {
    font-size: $text-xl;
  }
  .bigTitleSize {
    font-size: $text-xxl;
  }
}

@media #{$laptop} {
  .smallTextSize, .smallLinkSize {
    font-size: $text-xs;
  }
  .textSize, .linkTextSize {
    font-size: $text-s;
  }
  .bigTextSize {
    font-size: $text-l;
  }

  .smallTitleSize, .smallLinkTextSize {
    font-size: $text-m;
  }
  .titleSize {
    font-size: $text-l;
  }
  .bigTitleSize {
    font-size: $text-xl;
  }
}

@media #{$mobile} {
  .smallTextSize, .smallLinkSize {
    font-size: 12px;
    line-height: 16px;
  }
  .textSize, .linkTextSize {
    font-size: 16px;
    line-height: 24px;
  }
  .bigTextSize {
    font-size: 22px;
    line-height: 30px;
  }

  .smallTitleSize, .smallLinkTextSize {
    font-size: 18px;
  }
  .titleSize {
    font-size: 32px;
  }
  .bigTitleSize {
    font-size: 42px;
  }
}

// Removes arrows from number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}