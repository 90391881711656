@import 'containers/app/app.scss';

.MOBILE_IMPROVE_SUMMARY-improveSummary {
  display: flex;
  flex-direction: column;

  .MOBILE_IMPROVE_SUMMARY-howToImprove {
    text-align: center;
    border-top: 1px solid $lightGrey;
  }

  .MOBILE_IMPROVE_SUMMARY-noWorkoutsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .MOBILE_IMPROVE_SUMMARY-workoutBreakdowns {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .MOBILE_IMPROVE_SUMMARY-resourceContainer {
    text-align: center;
    margin-bottom: $spacing-m;
  }

  .MOBILE_IMPROVE_SUMMARY-productsAndMonkContainer {
    display: flex;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    
    .MOBILE_IMPROVE_SUMMARY-productMonkImage {
      max-width: 100%;
      height: auto;
      aspect-ratio: 7/10;
    }

    .MOBILE_IMPROVE_SUMMARY-productContainer {
      position: absolute;
      width: 87px;
      height: 183px;
      overflow-y: auto;
      left: 209px;
      bottom: 120px;

      .MOBILE_IMPROVE_SUMMARY-product {
        line-height: 0px;

        .MOBILE_IMPROVE_SUMMARY-productImage {
          max-width: 100%;
          height: auto;
          aspect-ratio: 1;
        }
      }
    }
  }
}