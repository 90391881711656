@import 'containers/app/app.scss';

.SIGNUP_MODAL-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .SIGNUP_MODAL-checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    margin-bottom: 32px;

    .SIGNUP_MODAL-checkbox {

    }

    .SIGNUP_MODAL-emailCheckboxText {
      
    }
  }

  .SIGNUP_MODAL-signUpText {
    margin-bottom: 32px;
  }

  
  .SIGNUP_MODAL-signUpErrorText {
    color: red;
    margin-bottom: 32px;
    padding: 8px;
    width: 70%;
    text-align: center;
  }

  .SIGNUP_MODAL-signUpExplanationText {
    padding: 30px;
    text-align: center;
    margin-bottom: 32px;
  }

  .SIGNUP_MODAL-signUpButton {
    margin-top: 16px;
    margin-bottom: 32px;
  }
}
