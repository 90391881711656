@import 'containers/app/app.scss';

.TAKE_HOME_WORKOUT_QUESTION_PAGE-container {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow-y: auto;

    .TAKE_HOME_WORKOUT_QUESTION_PAGE-workoutNameAndIconContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .TAKE_HOME_WORKOUT_QUESTION_PAGE-workoutName {

        }

        .TAKE_HOME_WORKOUT_QUESTION_PAGE-workoutIconContainer {
            height: auto;
            width: auto;

            .TAKE_HOME_WORKOUT_QUESTION_PAGE-workoutIcon {
                max-width: 100%;
                height: auto;
                aspect-ratio: 1;
                filter: grayscale(100%);
            }
        }
    }
    
    .TAKE_HOME_WORKOUT_QUESTION_PAGE-formLink {
        text-decoration: none;
    }
}

@media #{$mobile} {
    .TAKE_HOME_WORKOUT_QUESTION_PAGE-container {
        margin-bottom: 8px;
        min-height: 350px;

        .TAKE_HOME_WORKOUT_QUESTION_PAGE-countdownContainer {
            width: 75%;
        }

        .TAKE_HOME_WORKOUT_QUESTION_PAGE-workoutNameAndIconContainer {
            text-align: center;
            margin-right: $spacing-s;
        }

        .TAKE_HOME_WORKOUT_QUESTION_PAGE-workoutValueInputContainer {
            height: auto;

            .TAKE_HOME_WORKOUT_QUESTION_PAGE-workoutName {
                margin-bottom: 6px;
            }
        }

        .TAKE_HOME_WORKOUT_QUESTION_PAGE-inputsContainer {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-around;
            margin-bottom: 0px;
        }
    }
}

.list::-webkit-scrollbar {
    -webkit-appearance: scrollbartrack-vertical;
}    
