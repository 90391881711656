@import 'containers/app/app.scss';

.WORKOUT_BREAKDOWN-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .WORKOUT_BREAKDOWN-textContainer {
    display: flex;
    flex-direction: row;

    .WORKOUT_BREAKDOWN-formUrlLink {
      text-decoration: none;
    }
  }
}
