@import 'containers/app/app.scss';

.HAMBURGER_MENU-openHamburgerMenuIcon {
  position: absolute;
  right: 16px;
  top: 16px;
  max-width: 100%;
  aspect-ratio: 24/20;
}

.HAMBURGER_MENU-openHamburgerMenuContainerClosed {
  transform: translate3d(100%, 0px, 0px);
  width: 0px;
}


.HAMBURGER_MENU-hideElement {
  display: none;
}

.HAMBURGER_MENU-openHamburgerMenuContainer {
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 11;
  height: 100vh;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.95);
  transition: all 0.5s ease 0s;
  padding-left: 24px;

  .HAMBURGER_MENU-closeHamburgerMenuX {
    position: absolute;
    right: 16px;
    top: 16px;
    max-width: 100%;
    aspect-ratio: 24/20;
  }

  .HAMBURGER_MENU-hamburgerTitlesContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    margin-right: 10vw;

    .HAMBURGER_MENU-hamburgerTitleStyle {
      margin-bottom: 32px;
    }
  }
}