.SOCIAL_MENU-socialShareContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .SOCIAL_MENU-pretext {
    margin-right: 30px;
  }

  .SOCIAL_MENU-shareButton {
    margin-right: 10px;
  }
}
