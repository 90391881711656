@import 'containers/app/app.scss';

.LANDING_SECTION-container {
  display: flex;
  padding-top: 5vh;
  padding-left: 5vw;
  padding-right: 5vw;
  min-height: 95vh;

  .LANDING_SECTION-homePageImage {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;
  }

  .LANDING_SECTION-titleTextAndButtonsContainer {
    display: flex;
    flex-direction: column;
    width: 400px;

    .LANDING_SECTION-buttonsContainer {
      display: flex;
      flex-direction: row;
      margin-top: 16px;

        .LANDING_SECTION-leftButton {
          margin-right: 20px;
        }
    }
  }
}

@media #{$desktop} {
    .LANDING_SECTION-container {

      .LANDING_SECTION-titleTextAndButtonsContainer {
        width: 550px;
      }
  }
}