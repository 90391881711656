@import 'containers/app/app.scss';

.FITNESS_TYPES-maleBackground {
  background-color: $offWhite;
}

.FITNESS_TYPES-container {
  display: flex;
  flex-direction: column;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 5vw;
  padding-right: 5vw;
  width: 90vw;

  .FITNESS_TYPES-fitnessTypeTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .FITNESS_TYPES-fitnessTypeImageMobile {
    height: auto;
    max-width: 100%;
    aspect-ratio: 100/155;
  }

  .FITNESS_TYPES-fitnessTypesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;

    .FITNESS_TYPES-topRanking {
      min-width: 90vw;
      justify-content: center;
    }

    .FITNESS_TYPES-fitnessTypeContainer {
      display: flex;
      flex-direction: row;
      padding: 8px;
      width: 30%;

      .FITNESS_TYPES-fitnessTypeTextContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
  
      .FITNESS_TYPES-fitnessTypeImage {
        max-width: 100%;
        height: auto;
        aspect-ratio: 100/155;
        margin-right: 8px;
      }
    }
    @media #{$mobile} {
      .FITNESS_TYPES-fitnessTypeContainer {
        flex-direction: column;
        align-items: center;
        padding: 10px;
        width: 40%;
      }
    }
  }
}
