@import 'containers/app/app.scss';

.MODAL-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vw;
    min-height: 60vh;
    max-height: 95vh;
    background:white;
    z-index: 10;
    box-shadow: 15px 20px 30px black;
    overflow-y: auto;

    .MODAL-closeButtonContainer {
        display: flex;
        justify-content: flex-end;

        .MODAL-closeButton {
            padding: 20px;
        }
    }
}

@media #{$mobile} {
    .MODAL-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85vw;
        min-height: 60vh;
        max-height: 80vh;
        background:white;
        z-index: 10;
        box-shadow: 5px 7px 12px black;
        overflow-y: auto;
    
        .MODAL-closeButtonContainer {
            display: flex;
            justify-content: flex-end;
    
            .MODAL-closeButton {
                padding: 20px;
            }
        }
    }
}