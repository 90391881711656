@import 'containers/app/app.scss';

.PROFILE_PAGE-container {
  display: flex;
  flex-direction: column;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;

  .PROFILE_PAGE-titleBarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 15vh;
    border-bottom: 1px solid $lightGrey;

    .PROFILE_PAGE-accountButtonsContainer {
      display: flex;
      flex-direction: row;

      .PROFILE_PAGE-about {
        margin-right: $spacing-s;
        cursor: pointer;
      }

      .PROFILE_PAGE-logIn {

      }

      .PROFILE_PAGE-loggedInContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        .PROFILE_PAGE-logoutContainer {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-right: $spacing-xl;

          .PROFILE_PAGE-username {
            margin-bottom: $spacing-xxs;
          }
        }
      }

      .PROFILE_PAGE-notLoggedInContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        .PROFILE_PAGE-loginContainer {
          display: flex;
          flex-direction: row;
          text-align: center;
          margin-right: $spacing-s;
          
          div:first-of-type {
            margin-right: $spacing-s;
          }

        }
      }
    }

    .PROFILE_PAGE-siteTitle {
      cursor: pointer;
    }
  }

  .PROFILE_PAGE-noData {
    text-align: center;
    margin-top: 10vh;
  }

  .PROFILE_PAGE-profileAndImprovementsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: $spacing-m;

    .PROFILE_PAGE-profileSummary {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      width: 60%;

      .PROFILE_PAGE-resourceContainer {
        display: flex;
        align-items: center;
        width: 100%;
        padding: $spacing-m;
        padding-top: $spacing-l;
        text-align: left;
        border-top: 1px solid $lightGrey;

        .PROFILE_PAGE-swoleExplanationContainer {

          .PROFILE_PAGE-sacredTexts {
            
          }
        }

        .PROFILE_PAGE-productsAndMonkContainer {
          display: flex;
          position: relative;
          width: 300px;
          margin-left: $spacing-m;
  
          .PROFILE_PAGE-productMonkImage {
            max-width: 100%;
            height: auto;
            aspect-ratio: 7/10;
          }
  
          .PROFILE_PAGE-productScrollContainer {
            position: absolute;
            width: 95px;
            height: 185px;
            overflow-y: auto;
            overflow-x: hidden;
            left: 208px;
            bottom: 118px;
  
            .PROFILE_PAGE-productImageContainer {

              .PROFILE_PAGE-swoleExplanationContainer {

              }
  
              .PROFILE_PAGE-productImage {
                margin-top: 12px;
              }
            }
          }
        }
      }

      
  
      .PROFILE_PAGE-profileContainer {
        display: flex;
        margin-bottom: $spacing-l;
        align-items: center;
        justify-content: space-around;
  
        .PROFILE_PAGE-rankingExplanationContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 90%;
          max-width: 300px;

          .PROFILE_PAGE-nameAndRankContainer {
            display: flex;
            flex-direction: column;
            text-align: center;
          }

          .PROFILE_PAGE-inspirationalQuoteContainer {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: 16px;
          }
        }
  
        .PROFILE_PAGE-rankingImage {
          height: auto;
          max-width: 100%;
          aspect-ratio: 150/230
        }
      }
    }
  }
}

@media #{$desktop} {
  .PROFILE_PAGE-container {
  
    .PROFILE_PAGE-profileAndImprovementsContainer {
  
      .PROFILE_PAGE-profileSummary {
    
        .PROFILE_PAGE-profileContainer {
    
          .PROFILE_PAGE-rankingExplanationContainer {
            width: 550px;
          }
        }
      }
    }
  }
}
