@import 'containers/app/app.scss';

.MOBILE_INTRO_QUESTIONS-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 4vh;

    .MOBILE_INTRO_QUESTIONS-questionArea {
        display: flex;
        flex-direction: column;
        margin-bottom: 6vh;

        .MOBILE_INTRO_QUESTIONS-answerSection {
            display: flex;
            flex-direction: row;
            align-items: center;

            .MOBILE_INTRO_QUESTIONS-trialTypeContainer {
                margin-right: $spacing-s;
            }

            .MOBILE_INTRO_QUESTIONS-numperInputAndMetricChoiceContainer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .MOBILE_INTRO_QUESTIONS-metricChoiceContainer {
                    display: flex;

                    .MOBILE_INTRO_QUESTIONS-us {
                        margin-right: 30px;
                    }
                }
            }

            .MOBILE_INTRO_QUESTIONS-sliderBar {
                
            }

            .MOBILE_INTRO_QUESTIONS-ageNumberInput {
                font-family: $textFont;
                color: $darkGrey;
                margin-right: 30px;
                width: 25%;
                text-align: center;
                font-weight: bold;
            }

            .MOBILE_INTRO_QUESTIONS-genderButton {
                margin-right: 12px;
            }

            .MOBILE_INTRO_QUESTIONS-marginRight {
                margin-right: 12px;
            }


            .MOBILE_INTRO_QUESTIONS-selectedButton {
                background: $darkGrey;
                color: white;
                cursor: pointer;
            }
        }

        .MOBILE_INTRO_QUESTIONS-questionSection {
            display: flex;
            flex-direction: row;

            .MOBILE_INTRO_QUESTIONS-questionName {
                margin-right: 4px;
            }
        }
    }

}