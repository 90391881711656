@import 'containers/app/app.scss';

.PERCENTAGE_BAR-container {
    display: flex;
    flex-direction: row;
    height: 30px;
    position: relative;
    margin-top: $spacing-s;
    z-index: 1;

    .PERCENTAGE_BAR-midPoint {
        position: absolute;
        height: 20px;
        width: 5px;
        background-color: black;
        margin-left: auto;
        margin-right: auto;
        left: 3px;
        right: 0;
        bottom: 16px;
        z-index: 2;
    }

    .PERCENTAGE_BAR-benchmarkContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 24px;
        height: 0px;
        z-index: 1;

        .PERCENTAGE_BAR-benchmarkTooltipContainer {
            height: 16px;
            left: 6px;
            z-index: 2;

            .PERCENTAGE_BAR-benchmark {
                width: 0;
                height: 0;
                border: 8px solid transparent;
                border-bottom: 4px solid var(--benchmark-color);
                position: relative;
                // top: -24px;
                left: 8px;
            }
            .PERCENTAGE_BAR-benchmark:after {
                content: '';
                position: absolute;
                left: -8px;
                top: 4px;
                width: 0;
                height: 0;
                border: 8px solid transparent;
                border-top: 12px solid var(--benchmark-color);
            }
            .PERCENTAGE_BAR-benchmark:hover {
                z-index: 3;
            }
        }
        .PERCENTAGE_BAR-benchmarkTooltipContainer:hover {
            z-index: 3;
        }
    }

    .PERCENTAGE_BAR-barContainer {
        display: flex;
        flex-direction: row;
        height: 25px;
        width: 100%;
        padding-top: 6px;
    
        .PERCENTAGE_BAR-filled {
            background-color: $darkGrey;
            height: 100%;
        }
    
        .PERCENTAGE_BAR-empty {
            background-color: $lightGrey;
            height: 100%;
        }
    }


}

@media #{$mobile} {
    .PERCENTAGE_BAR-container {

        .PERCENTAGE_BAR-midPoint {
            height: 16px;
            width: 4px;
            left: 2px;
            bottom: 20px;
        }
    
        .PERCENTAGE_BAR-barContainer {
            padding-top: 3px;
        }
    }
}

@media #{$desktop} {
    .PERCENTAGE_BAR-container {
        width: 100%;

        .PERCENTAGE_BAR-midPoint {
            height: 24px;
            width: 6px;
            left: 3px;
            bottom: 12px;
        }
    
        .PERCENTAGE_BAR-barContainer {
            padding-top: 10px;
        }
    }
}