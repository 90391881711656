@import 'containers/app/app.scss';

.ABOUT-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: $spacing-l * 2;
  width: 100vw;
  background-color: $offWhite;

  .ABOUT-creditsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $spacing-l * 2;

    .ABOUT-personContainer {
      display: flex;
      flex-direction: column;
      width: 24%;

      .ABOUT-personLink {
        text-decoration: none;
        width: fit-content;
      }

      .ABOUT-personImage {
        max-width: 100%;
        height: auto;
        aspect-ratio: 2/1;
        margin-bottom: $spacing-xs;
      }
    }
  }

  .ABOUT-bottomAboutContainer {
    display: flex;
    justify-content: space-between;

    .ABOUT-supportContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .ABOUT-helpUsImproveButton {
        width: 250px;
        margin-bottom: $spacing-m;
      }
      
      .ABOUT-shareContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-around;
        max-height: 25vh;
      }
    }
  }

  .ABOUT-textContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-xl;

    .ABOUT-textSection {
      width: 48%;
      padding: 5px;
    }
  }
}

.ABOUT-extraCreditsContainer {
  display: flex;
  flex-direction: column;

  @media #{$mobile} {
    padding: 5vw;
  }
}

.ABOUT-otherSitesContainer {
  @media #{$mobile} {
    padding: 5vw;
  }

  .ABOUT-wtfDoTheyWantContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .ABOUT-wtfTitleContainer {
      text-decoration: none;
      cursor: pointer;
      margin-top: $spacing-m;
      margin-bottom: $spacing-m;

      .ABOUT-wtfTitle {
        
      }
    }

    .ABOUT-wtfDoTheyWantDescriptionContainer {
      display: flex;
      flex-direction: column;
      width: 30%;
      margin-bottom: $spacing-s;

      @media #{$mobile} {
        width: 90%;
      }
    }

    .ABOUT-wtfImageContainer {
      max-width: 50%;

      @media #{$mobile} {
        max-width: 100%;
      }

      .ABOUT-wtfImage {
        max-width: 100%;
        height: auto;
        aspect-ratio: 2/1;
      }
    }
  }
}

.ABOUT-privacyPolicyContainer {
  display: flex;
  flex-direction: column;

  @media #{$mobile} {
    padding: 5vw;
  }
}