@import 'containers/app/app.scss';

.STYLED_TEXT-container {
  display: inline;
  color: black;
}

.STYLED_TEXT-bold {
  font-weight: bold;
}

.STYLED_TEXT-secondary {
  color: $darkGrey;
}

.STYLED_TEXT-third {
  color: $lightGrey;
}

.STYLED_TEXT-smallLink {
  text-decoration: underline;
  font-family: $textFont;
  color: $lightGrey;
}
.STYLED_TEXT-smallLink:hover {
  cursor: pointer;
  color: $darkGrey;
}

.STYLED_TEXT-smallTitle {
  font-weight: bold;
  font-family: $titleFont;
}

.STYLED_TEXT-smallLinkText {
  font-weight: bold;
  font-family: $titleFont;
}
.STYLED_TEXT-smallLinkText:hover {
  cursor: pointer;
}

.STYLED_TEXT-title {
  font-weight: bold;
  font-family: $titleFont;
}

.STYLED_TEXT-bigTitle {
  font-weight: bold;
  font-family: $titleFont;
}

.STYLED_TEXT-bigText {
  font-family: $textFont;
}

.STYLED_TEXT-text {
  font-family: $textFont;
}

.STYLED_TEXT-linkText {
  font-family: $textFont;
  text-decoration: underline;
}

.STYLED_TEXT-smallText {
  font-family: $textFont;
}
