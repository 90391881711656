@import 'containers/app/app.scss';

.DROPDOWN_SELECT-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    .DROPDOWN_SELECT-select {
        margin-right: $spacing-xs;
    }
        @media #{$mobile} {
        .DROPDOWN_SELECT-select {
            min-width: 5rem;
        }
    }

    .DROPDOWN_SELECT-label {
        color: $lightGrey;
        align-self: center;
    }
}

.DROPDOWN_SELECT-reps {
    margin-left: $spacing-l;

    @media #{$mobile} {
        margin-left: 0px;
    }
}

@media #{$mobile} {
    .DROPDOWN_SELECT-container {
        align-items: center;
    }
}