@import 'containers/app/app.scss';

.QUESTION_PAGE-container {
    display: flex;
    width: 100vw;
    height: 100vh;

    .QUESTION_PAGE-leftPanel {
        display: flex;
        flex-direction: column;
        width: 40%;
        padding: $spacing-xl;
        background-size: cover;
        background-repeat: no-repeat;
        color: white;
        filter: grayscale(100%);

        .QUESTION_PAGE-descriptionBar {
            display: flex;
            flex-direction: column;

            .QUESTION_PAGE-description {
                color: $lightGrey;
            }

            .QUESTION_PAGE-descriptionTitle {
                color: white;
                margin-bottom: $spacing-l;
            }
        }

        .QUESTION_PAGE-progressContainer {
            display: flex;
            flex-direction: column;
            margin-top: auto;
            width: 90%;

            .QUESTION_PAGE-progressBarContainer {
                display: flex;
                flex-direction: row;
                height: 8px;
                margin-bottom: $spacing-m;

                .QUESTION_PAGE-leftProgressBar {
                    background: white;
                }
                .QUESTION_PAGE-rightProgressBar {
                    background: $darkGrey;
                }
            }

            .QUESTION_PAGE-progressText {
                color: $lightGrey;
            }
        }

        .QUESTION_PAGE-navigationContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .QUESTION_PAGE-nextButton {
                margin-top: auto;
            }
        }

        .QUESTION_PAGE-topBar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $spacing-l;

            .QUESTION_PAGE-title {
                cursor: pointer;
                color: white;
            }
        }

    }

    .QUESTION_PAGE-rightPanel {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: $spacing-xl;
    }
}
