@import 'containers/app/app.scss';

.MOBILE_ABOUT-container {
  display: flex;
  flex-direction: column;
  padding: 5vw;
  width: 90vw;
  background-color: $offWhite;
}

.MOBILE_ABOUT-creditsContainer {
  display: flex;
  flex-direction: column;
  padding: 5vw;
  width: 90vw;
  background-color: white;

  .MOBILE_ABOUT-personContainer {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 16px;

    .MOBILE_ABOUT-personLink {
      text-decoration: none;
      width: fit-content;
    }

    .MOBILE_ABOUT-personImage {
      width: 90vw;
      height: auto;
      aspect-ratio: 2/1;
    }

    .MOBILE_ABOUT-nameAndTitleContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 10px;
    }
  }

  .MOBILE_ABOUT-helpUsImproveButton {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}