@import 'containers/app/app.scss';

.MOBILE_PROFILE_PAGE-container {
  display: flex;
  flex-direction: column;
  padding: 5vw;

  .MOBILE_PROFILE_PAGE-statsBreakdown {
    display: flex;
    margin-top: 20vh;
    text-align: center;
  }
}
