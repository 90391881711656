@import 'containers/app/app.scss';

.INTRO_QUESTIONS-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;

    .INTRO_QUESTIONS-questionsContainer {

        .INTRO_QUESTIONS-questionRow {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            margin-bottom: $spacing-l;

            .INTRO_QUESTIONS-answerSection {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 70%;

                .INTRO_QUESTIONS-trialTypeContainer {
                    display: flex;
                    margin-right: $spacing-m;
                }

                .INTRO_QUESTIONS-numperInputAndMetricChoiceContainer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 80%;

                    .INTRO_QUESTIONS-metricChoiceContainer {
                        display: flex;
                        margin-left: $spacing-l;

                        .INTRO_QUESTIONS-us {
                            margin-right: 30px;
                        }
                    }
                }

                .INTRO_QUESTIONS-sliderBar {
                    width: 25vw;
                }

                .INTRO_QUESTIONS-ageNumberInput {
                    font-family: $textFont;
                    color: $darkGrey;
                    margin-right: 30px;
                    width: 5vw;
                    text-align: center;
                    font-weight: bold;
                }

                .INTRO_QUESTIONS-genderButton {
                    margin-right: 30px;
                }

                .INTRO_QUESTIONS-marginRight {
                    margin-right: 30px;
                }


                .INTRO_QUESTIONS-selectedButton {
                    background: $darkGrey;
                    color: white;
                    cursor: pointer;
                }
            }

            .INTRO_QUESTIONS-questionSection {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 25%;

                .INTRO_QUESTIONS-questionName {
                    margin-right: 4px;
                    font-size: $text-l;
                }
            }
        }
    }
}

@media #{$desktop} {
    .INTRO_QUESTIONS-container {
        .INTRO_QUESTIONS-questionsContainer {
            .INTRO_QUESTIONS-questionRow {
                margin-bottom: $spacing-xl;
            }
        }
    }
}