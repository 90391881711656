@import 'containers/app/app.scss';

.MOBILE_LANDING_SECTION-container {
  display: flex;
  flex-direction: column;
  padding: 5vw;
  width: 90vw;

  .MOBILE_LANDING_SECTION-homePageImage {
    width: 90vw;
    height: auto;
    aspect-ratio: 1;
  }

  .MOBILE_LANDING_SECTION-buttonsContainer {
    display: flex;
    flex-direction: row;

    .MOBILE_LANDING_SECTION-leftButton {
      margin-right: 8px;
    }
  }

}
