@import 'containers/app/app.scss';

.STYLED_BUTTON-container {
  position: relative;

  .STYLED_BUTTON-button {
    background: white;
    color: $darkGrey;
    border: solid 1px $darkGrey;
    border-radius: 8px;
    outline: none;
    padding: $spacing-xs $spacing-s;
    line-height: 1;
    font-size: $text-s;
    transition: all 0.16s;
    letter-spacing: $text-s * 0.05;
    font-weight: 300;
  }

  @media #{$desktop} {
    .STYLED_BUTTON-button {
      width: 195px;
      min-height: 80px;
      font-size: $text-l;
      letter-spacing: $text-l * 0.05;
      border: solid 2px $darkGrey;
    }
  }

  @media #{$mobile} {
    .STYLED_BUTTON-button {
      min-height: 40px;
    }
  }

  .STYLED_BUTTON-button:hover {
    background: $darkGrey;
    color: white;
    cursor: pointer;
  }
  .STYLED_BUTTON-button:disabled {
    background: $darkGrey;
    color: white;
    cursor: not-allowed;
  }

  .STYLED_BUTTON-smallButton {
    min-height: 50px;
  }
  @media #{$desktop} {
    .STYLED_BUTTON-smallButton {
      min-height: 80px;
    }
  }

  .STYLED_BUTTON-bigButton {
    min-height: 75px;
    min-width: 150px;
    font-size: $text-s;
    line-height: $text-m;
    padding: $spacing-s $spacing-m;
  }
  @media #{$desktop} {
    .STYLED_BUTTON-bigButton {
      font-size: $text-l;
      line-height: $text-l;
      min-height: 100px;
      min-width: 260px;
    }
  }

  .STYLED_BUTTON-selected {
    background: $darkGrey;
    color: white;
    cursor: pointer;
  }
}