@import 'containers/app/app.scss';

.QUESTION_MARK_HOVER-container {
    display: flex;
    position: absolute;
    top: 4px;
    right: 4px;

    .QUESTION_MARK_HOVER-questionMark {
        border-radius: 50%;
        width: 22px;
        height: 22px;
        background: #fff;
        border: 1px solid $darkGrey;
        color: $darkGrey;
        text-align: center;
        margin-left: $spacing-xxs;
        font-size: $text-xs;
    }

    .QUESTION_MARK_HOVER-questionMark:hover {
        color: white;
        background-color: $darkGrey;
    }
}

@media #{$mobile} {
    .QUESTION_MARK_HOVER-container {
        top: 6px;
        right: 6px;
    }
}

@media #{$desktop} {
    .QUESTION_MARK_HOVER-container {
        top: 12px;
        right: 12px;

        .QUESTION_MARK_HOVER-questionMark {
            font-size: $text-xs;
            width: $text-m;
            height: $text-m;
            line-height: $text-m;
        }
    }
}