@import 'containers/app/app.scss';

.MOBILE_PROFILE_PAGE_RANKING-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4vh;

    .MOBILE_PROFILE_PAGE_RANKING-chooseMuscleGroupContainer {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 16px;
        margin-bottom: 16px;

        .MOBILE_PROFILE_PAGE_RANKING-muscleGroupButton {
            width: 40vw;
            margin-bottom: 20px;
        }
    }

    .MOBILE_PROFILE_PAGE_RANKING-rankingTitle {
        margin-bottom: 20px;
    }

    .MOBILE_PROFILE_PAGE_RANKING-inspirationalQuoteContainer {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: $spacing-s;
        margin-bottom: $spacing-s;
        min-height: 250px;
    }

    .MOBILE_PROFILE_PAGE_RANKING-currentMuscleGroupImage {
        max-width: 100%;
        height: auto;
        aspect-ratio: 200/273;
    }

    .MOBILE_PROFILE_PAGE_RANKING-statsBreakdown {
        align-self: center;
        border-top: 1px solid $lightGrey;
        width: 100%;
        text-align: center;
    }

    .MOBILE_PROFILE_PAGE_RANKING-personalizedRankingContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;

        .MOBILE_PROFILE_PAGE_RANKING-rankingImage {
            max-width: 100%;
            height: auto;
            aspect-ratio: 100/155;
        }

        .MOBILE_PROFILE_PAGE_RANKING-writtenRankingContainer {
            display: flex;
            flex-direction: column;
            text-align: center;
        }
    }
}
