$lightGrey: #b9b9b9;
$offWhite: #FCFAFB;
$darkGrey: #464646;

$titleFont: 'Luminari';
$textFont: 'Lato';

$mobile: "only screen and (max-width : 768px)";
$laptop: "only screen and (min-width : 768px) and (max-width: 1600px)";
$desktop: "only screen and (min-width : 1600px)";

$spacing-xl: 3.8rem;
$spacing-l: 2rem;
$spacing-m: 1.3rem;
$spacing-s: 1rem;
$spacing-xs: 0.8rem;
$spacing-xxs: 0.6rem;

$text-xxl: 4rem;
$text-xl: 2.8rem;
$text-l: 1.6rem;
$text-m: 1.3rem;
$text-s: 1rem;
$text-xs: 0.8rem;
$text-xxs: 0.6rem;
