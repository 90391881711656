@import 'containers/app/app.scss';

.WORKOUT_QUESTION_PAGE-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;

    .WORKOUT_QUESTION_PAGE-workoutSection {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 6vh;
        width: 100%;

        .WORKOUT_QUESTION_PAGE-workoutNameAndIconContainer {
            display: flex;
            flex-direction: column;
            width: 200px;
            margin-right: $spacing-l;

            .WORKOUT_QUESTION_PAGE-workoutName {
                
            }

            .WORKOUT_QUESTION_PAGE-workoutIconContainer {
                height: auto;
                width: auto;
                margin-top: $spacing-s;

                .WORKOUT_QUESTION_PAGE-workoutIcon {
                    max-width: 100%;
                    height: auto;
                    aspect-ratio: 1;
                    filter: grayscale(100%);
                }
            }
        }
        
        .WORKOUT_QUESTION_PAGE-formLink {
            text-decoration: none;
        }
    }

    .WORKOUT_QUESTION_PAGE-inputsContainer {
        display: flex;
        margin-bottom: $spacing-xs;
    }
}

@media #{$mobile} {
    .WORKOUT_QUESTION_PAGE-container {
        margin-bottom: 8px;
        overflow: visible;

        .WORKOUT_QUESTION_PAGE-workoutSection {

            .WORKOUT_QUESTION_PAGE-workoutNameAndIconContainer {
                width: 150px;
                text-align: center;
                margin-right: $spacing-s;
            }
    
            .WORKOUT_QUESTION_PAGE-workoutValueInputContainer {
                height: auto;
    
                .WORKOUT_QUESTION_PAGE-workoutName {
                    margin-bottom: 6px;
                }
            }
        }

        .WORKOUT_QUESTION_PAGE-inputsContainer {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-around;
            margin-bottom: 0px;
        }
    }
}

  @media #{$desktop} {
    .WORKOUT_QUESTION_PAGE-container {
        margin-bottom: $spacing-s;

        .WORKOUT_QUESTION_PAGE-workoutSection {

            .WORKOUT_QUESTION_PAGE-workoutNameAndIconContainer {
                width: 250px;
                margin-right: $spacing-l;
                text-align: center;
            }
    
            .WORKOUT_QUESTION_PAGE-workoutValueInputContainer {
    
                .WORKOUT_QUESTION_PAGE-workoutName {
                    margin-bottom: $spacing-xs;
                }
            }
        }

        .WORKOUT_QUESTION_PAGE-inputsContainer {
        }
    }
}

.list::-webkit-scrollbar {
    -webkit-appearance: scrollbartrack-vertical;
}    
