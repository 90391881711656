@import 'containers/app/app.scss';

.UNSUBSCRIBE_LANDING-container {
  display: flex;
  flex-direction: column;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}
