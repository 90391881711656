@import 'containers/app/app.scss';

.LOGIN_MODAL-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .LOGIN_MODAL-logInErrorText {
    color: red;
    margin-bottom: 32px;
    padding: 8px;
    width: 70%;
    text-align: center;
  }

  .LOGIN_MODAL-logInText {
    margin-bottom: 8vh;
  }

  .LOGIN_MODAL-logInButton {
    margin-bottom: 8vh;
  }
}
