@import 'containers/app/app.scss';

.IMPROVE_SUMMARY-improveSummary {
  display: flex;
  flex-direction: column;
  width: 45%;
  padding-left: 10vw;
  padding-right: 16px;

  .IMPROVE_SUMMARY-statsBreakdownContainer {
    display: flex;
    flex-direction: column;

    .IMPROVE_SUMMARY-muscleGroupContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-bottom: $spacing-l;

      .IMPROVE_SUMMARY-currentMuscleGroupImage {
        max-width: 100%;
        height: auto;
        aspect-ratio: 150/230;
      }

      .IMPROVE_SUMMARY-muscleGroupButtonsContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        .IMPROVE_SUMMARY-muscleGroupButton {
          margin-top: 6px;
          margin-bottom: 6px;
          width: 100%;
        }
        @media #{$desktop} {
          .IMPROVE_SUMMARY-muscleGroupButton {
            min-height: auto;
            width: 200px;
            margin-top: $spacing-xs;
            margin-bottom:  $spacing-xs;
            padding: $spacing-s;
          }
        }
      }
    }
  }

  .IMPROVE_SUMMARY-noWorkoutsContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    padding-top: $spacing-l;
    border-top: 1px solid $lightGrey;
  }

  .IMPROVE_SUMMARY-workoutBreakdowns {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: $spacing-l;
    padding-bottom: $spacing-l;
  }

  .IMPROVE_SUMMARY-productScrollContainer {
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: 200px;
    padding-top: 4vh;

    .IMPROVE_SUMMARY-productImageContainer {
      height: 100px;
      width: 100px;
      margin-right: 16px;

      .IMPROVE_SUMMARY-productImage {
        height: auto;
        width: 100%;
        aspect-ratio: 1;
      }
    }


  }
}

.IMPROVE_SUMMARY-toggleBenchmarks {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-bottom: $spacing-m;

  .IMPROVE_SUMMARY-showBenchmarksQuestionMarkHover {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
  }
}